import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AppService {
  url = environment.baseUrl;

  constructor(private httpclient: HttpClient) {}

  pictureUrl =
    environment.baseUrl +
    '/emp/register/get/image?sysId=' +
    localStorage.getItem('sysId') +
    '&type=' +
    'PHOTO';

  getPictureUrl() {
    console.log(this.pictureUrl);

    this.pictureUrl =
      environment.baseUrl +
      '/emp/register/get/image?sysId=' +
      localStorage.getItem('sysId') +
      '&type=' +
      'PHOTO';

    return this.pictureUrl;
  }

  setPictureUrl() {
    this.pictureUrl =
      environment.baseUrl +
      '/emp/register/get/image?sysId=' +
      localStorage.getItem('sysId') +
      '&type=' +
      'PHOTOz';
    setTimeout(() => {
      this.pictureUrl =
        environment.baseUrl +
        '/emp/register/get/image?sysId=' +
        localStorage.getItem('sysId') +
        '&type=' +
        'PHOTO';
    }, 500);
  }

  public getMenuList(role: any): Observable<any> {
    return this.httpclient.get(
      this.url +
        '/v1/user/homepage/' +
        localStorage.getItem('langId') +
        '/mobileapp/sidebar/menulist?role=' +
        role
    );
  }
  // public getcatList():Observable<any>{
  //   return this.httpclient.post(this.url+"auth/register"+localStorage.getItem("langId")+"/cat/list");
  // }
  public createUser(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/auth/register', params);
  }
  public downloadDoc(id: any, file: any): Observable<any> {
    return this.httpclient.post(
      this.url + '/jobmelaUser/upload/document?agId=' + id,
      file,
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }
  public updateSupervisor(params: any): Observable<any> {
    return this.httpclient.post<any>(
      this.url + '/common/profile/update',
      params
    );
  }
  public createClient(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/clientmaster/create', params);
  }
  public createJobMela(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/jobmela/create', params);
  }

  public createLocation(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/location/create', params);
  }

  public createSupervisor(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/auth/register', params);
  }

  public createRegister(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/auth/register', params);
  }

  public updateLocation(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/location/update', params);
  }

  // public updateSupervisor(params:any): Observable<any> {
  //   return this.httpclient.post<any>(this.url + '/auth/register',params)

  // }

  public updateRegister(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/auth/register', params);
  }

  public createCompanyMaster(params: any): Observable<any> {
    return this.httpclient.post<any>(
      this.url + '/companymaster/create',
      params
    );
  }

  public createBranchMaster(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/branchmaster/create', params);
  }

  public UpdateCompanyMaster(params: any): Observable<any> {
    return this.httpclient.post<any>(
      this.url + '/companymaster/update',
      params
    );
  }

  public UpdateProfile(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/profile/update', params);
  }
  public UpdateCommonProfile(params: any): Observable<any> {
    return this.httpclient.post<any>(
      this.url + '/common/profile/update',
      params
    );
  }
  public UpdateBranchMaster(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/branchmaster/update', params);
  }

  public companyMasterList(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/companymaster/all/list?status=' + params
    );
  }

  public UpdateClient(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/clientmaster/update', params);
  }
  public updatejobMelaUser(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/jobmelaUser/update', params);
  }
  public updatejobMela(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/jobmela/update', params);
  }
  public login(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/auth/login', params);
  }
  public personaldetail(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/emp/register/create', params);
  }

  public personalUpddetail(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/emp/register/update', params);
  }

  public getPersonalDetailbyId(params: any): Observable<any> {
    if (
      params == null ||
      params == 'null' ||
      params == undefined ||
      params == ''
    ) {
      return this.httpclient.get<any>(this.url + '/emp/register/detail');
    } else {
      return this.httpclient.get<any>(
        this.url + '/emp/register/detail?sysId=' + params
      );
    }
  }

  public register(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/register/create', params);
  }

  public onboradingCreate(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/onboarding/create', params);
  }

  public onboradingDelete(params: any): Observable<any> {
    return this.httpclient.delete<any>(
      this.url + '/onboarding/delete/detail?onbId=' + params
    );
  }
  public onboradingUpload(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/onboarding/upload', params);
  }
  public onboradingUploadArchive(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/common/upload', params);
  }
  public onboradingUpdate(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/onboarding/update', params);
  }
  public onboradingUpdateSpecific(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/common/update', params);
  }
  public createArchive(params: any): Observable<any> {
    return this.httpclient.post<any>(
      this.url + '/common/onboard/archive/create',
      params
    );
  }
  public onboradingDetail(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/onboarding/detail?onbId=' + params
    );
  }
  public moveOnboardingToArchive(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/common/move/onboarding/to/archive?onbId=' + params
    );
  }
  public signinSignoutTime(params: any, date: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/auth/signin/singout/detail?userId=' +
        params +
        '&date=' +
        date
    );
  }

  public getSupervisorLocationList(params: any, date: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/common/supervisor/location/list?supervisor=' +
        params +
        '&date=' +
        date
    );
  }
  public onboradingList(params: any, paramsOption: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/onboarding/onboardList?status=' + params + paramsOption
    );
  }
  public getStockListFilter(
    params: any,
    paramsOption: any,
    searchValue: any,
    fromNo: any,
    toNo: any
  ): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/onboarding/onboardList/filter?status=' +
        params +
        paramsOption +
        '&search=' +
        searchValue +
        '&fromNo=' +
        fromNo +
        '&toNo=' +
        toNo
    );
    // http://localhost:8087/api/onboarding/onboardList/filter?status=PA&clientId=CLI&search=8056&fromNo=1&toNo=5
  }

  public getUserListFilter(
    params: any,
    paramsOption: any,
    searchValue: any,
    fromNo: any,
    toNo: any
  ): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/user/user/filter/list?status=' +
        params +
        paramsOption +
        '&search=' +
        searchValue +
        '&fromNo=' +
        fromNo +
        '&toNo=' +
        toNo
    );
    // http://localhost:8087/api/onboarding/onboardList/filter?status=PA&clientId=CLI&search=8056&fromNo=1&toNo=5
  }
  // public getStockListFilter(searchValue,fromNo,toNo):Observable<any>{
  //   return this.httpclient.get(this.baseUrl+"/api/fixedasset/all/list/filter?searchValue="+searchValue+"&fromNo="+fromNo+"&toNo="+toNo+"&lang="+localStorage.getItem("languageId"));

  // }
  public downloadJobmelaDoc(agId: any, type: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/jobmelaUser/document?agId=' + agId + '&type=' + type,
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }
  public locationDownloadExcel(
    userId: any,
    fmDate: any,
    toDate: any
  ): Observable<any> {
    return this.httpclient.get(
      this.url +
        '/common/location/download/excel?userId=' +
        userId +
        '&fmdate=' +
        fmDate +
        '&todate=' +
        toDate,
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }
  public onboradingAlterDetail(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/common/OnboardArchieve/getdetail?onbId=' + params
    );
  }
  public employeeDetail(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/employee/emp/detail?empId=EMP-0001'
    );
  }
  public activeRole(): Observable<any> {
    return this.httpclient.get<any>(this.url + '/common/role/list');
  }
  public dashBoardGraphCount(): Observable<any> {
    return this.httpclient.get<any>(this.url + '/common/user/dashboard/count');
  }
  public notOnboardedUser(): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/common/user/not/onboarded/list'
    );
  }

  public activeBranch(): Observable<any> {
    return this.httpclient.get<any>(this.url + '/common/branch/list');
  }
  public pendingDashBoardList(): Observable<any> {
    return this.httpclient.get<any>(this.url + '/common/pending');
  }
  public activeLocation(): Observable<any> {
    return this.httpclient.get<any>(this.url + '/common/location/list');
  }
  public onboardApproval(
    action: any,
    userId: any,
    remarks: any
  ): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/onboarding/approval?action=' +
        action +
        '&onbId=' +
        userId +
        '&remarks=' +
        remarks
    );
  }
  public userApproval(action: any, userId: any, remarks: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/user/approval?action=' +
        action +
        '&userId=' +
        userId +
        '&remarks=' +
        remarks
    );
  }
  public updateCategory(userid: any, category: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/onboarding/change/category?userId=' +
        userid +
        '&category=' +
        category
    );
  }
  public updateStatus(
    userid: any,
    category: any,
    dateOfExit: any
  ): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/onboarding/change/status?userId=' +
        userid +
        '&empStatus=' +
        category +
        '&dateOfExit=' +
        dateOfExit
    );
  }
  public getClientDetailByid(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/clientmaster/details?clientId=' + params
    );
  }
  public getjobMelaById(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/jobmela/detail?agId=' + params
    );
  }

  public getLocationDetailByid(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/location/detail?lcnCode=' + params
    );
  }

  public getNotification(params: any, params1: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/common/user/approval/list?userId=' +
        params +
        '&adminYn=' +
        params1
    );
  }
  public getSupervisorDetailById(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/user/user/detail?userId=' + params
    );
  }
  public mobileAlreadyExists(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/onboarding/mobile/already/exists?mobileNo=' + params
    );
  }
  public emailAlreadyExists(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/onboarding/email/already/exists?mailId=' + params
    );
  }
  public aadhaarAlreadyExists(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/onboarding/aadhaar/already/exists?aadhaarNo=' + params
    );
  }
  public actNoAlreadyExists(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/onboarding/actNo/already/exists?actNo=' + params
    );
  }
  public getCompanyMasterDetailByid(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/companymaster/details?compId=' + params
    );
  }

  public getProfileDetailById(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/common/profile/details?userId=' + params
    );
  }

  public getBranchMasterDetailById(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/branchmaster/details?agId=' + params
    );
  }

  public deleteLocation(params: any): Observable<any> {
    return this.httpclient.delete<any>(
      this.url + '/location/delete?Code=' + params
    );
  }

  public deleteSupervisor(params: any): Observable<any> {
    return this.httpclient.delete<any>(
      this.url + '/user/user/delete?id=' + params
    );
  }

  public companyList(): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/companymaster/all/list?status=WIP'
    );
  }
  public locationList(params: any): Observable<any> {
    return this.httpclient.get<any>(this.url + '/location/list?status=WIP');
  }

  public supervisorList(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/user/supervisor/list?status=WIP'
    );
  }
  public getClientByCocn(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/common/location/client/bylcnCode?lcnCode=' + params
    );
  }

  public branchList(): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/branchmaster/all/list?status=WIP'
    );
  }

  public deleteCompany(params: any): Observable<any> {
    return this.httpclient.delete<any>(
      this.url + '/companymaster/delete?compId=' + params
    );
  }

  public deleteBranch(params: any): Observable<any> {
    return this.httpclient.delete<any>(
      this.url + '/branchmaster/delete?agId=' + params
    );
  }

  public clientList(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/clientmaster/all/list?status=ALL'
    );
  }

  public clientListFilter(
    params: any,
    search: any,
    fromNo: any,
    toNo: any
  ): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/clientmaster/all/list/filter?status=ALL&search=' +
        search +
        '&fromNo=' +
        fromNo +
        '&toNo=' +
        toNo
    );
  }
  public supervisorListFilter(
    params: any,
    search: any,
    fromNo: any,
    toNo: any
  ): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/user/supervisor/list/filter?status=WIP&search=' +
        search +
        '&fromNo=' +
        fromNo +
        '&toNo=' +
        toNo
    );
  }
  public jobMelaList(): Observable<any> {
    return this.httpclient.get<any>(this.url + '/jobmela/list');
  }
  public superAdminList(): Observable<any> {
    return this.httpclient.get<any>(this.url + '/common/superAdmin/list');
  }
  public jobMelaUserList(params: any, fromNo: any, toNo: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/jobmelaUser/all/list/filter?search=' +
        params +
        '&fromNo=' +
        fromNo +
        '&toNo=' +
        toNo
    );
  }
  public locationListFilter(
    params: any,
    search: any,
    fromNo: any,
    toNo: any
  ): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/location/list/filter?status=WIP&search=' +
        search +
        '&fromNo=' +
        fromNo +
        '&toNo=' +
        toNo
    );
  }
  public deleteClient(params: any): Observable<any> {
    return this.httpclient.delete<any>(
      this.url + '/clientmaster/delete?clientId=' + params
    );
  }
  public deleteJobMela(params: any): Observable<any> {
    return this.httpclient.delete<any>(
      this.url + '/jobmela/delete?agId=' + params
    );
  }
  public activeSupervisor(): Observable<any> {
    return this.httpclient.get<any>(this.url + '/common/supervisor/list');
  }
  public activeClient(): Observable<any> {
    return this.httpclient.get<any>(this.url + '/common/client/list');
  }
  public profileDetails(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/common/profile/details?userId=' + params
    );
  }
  public genericDetails(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/common/genric/list/bycatid?catId=' + params
    );
  }
  public genericDetailsUserCatg(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/common/userCatg/usermaster/list?userCategory=' + params
    );
  }
  public userlist(params: any, paramsOption: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/user/user/list?status=' + params + paramsOption
    );
  }
  public deleteUser(params: any): Observable<any> {
    return this.httpclient.delete<any>(
      this.url + '/user/user/delete?id=' + params
    );
  }
  public getUserDetails(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/user/user/detail?userId=' + params
    );
  }
  public getgallerylistbytype(details: any): Observable<any> {
    return this.httpclient.post(
      `${this.url}/v1/user/media/${localStorage.getItem('langId')}/list`,
      details
    );
  }

  public deleteGeneric(params: any): Observable<any> {
    return this.httpclient.delete<any>(
      this.url + '/genericmaster/delete?key=' + params
    );
  }
  public deleteGenericDetail(params: any): Observable<any> {
    return this.httpclient.delete<any>(
      this.url + '/genericmaster/delete/detail?agId=' + params
    );
  }
  public createGeneric(params: any): Observable<any> {
    return this.httpclient.post<any>(
      this.url + '/genericmaster/create',
      params
    );
  }

  public updateGeneric(params: any): Observable<any> {
    return this.httpclient.post<any>(
      this.url + '/genericmaster/update',
      params
    );
  }
  public genericList(): Observable<any> {
    return this.httpclient.get<any>(this.url + '/genericmaster/all/list');
  }
  public genricDetails(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/genericmaster/getDetailById?key=' + params
    );
  }
  public userImageUpload(userImageUpload: any): Observable<any> {
    return this.httpclient.post<any>(
      this.url + '/user/user/image',
      userImageUpload
    );
  }
  public adminList(): Observable<any> {
    return this.httpclient.get<any>(this.url + '/common/admin/list');
  }
  public getAuthDetails(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/authorization/authorization/detail?key=' + params
    );
  }
  public getAuthList(): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/authorization/authorization/keyList'
    );
  }
  public locationByClient(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/common/client/location/byclientId?clientId=' + params
    );
  }
  public createAuth(params: any): Observable<any> {
    return this.httpclient.post<any>(
      this.url + '/authorization/create',
      params
    );
  }
  public updateAuth(params: any): Observable<any> {
    return this.httpclient.post<any>(
      this.url + '/authorization/authorization/update',
      params
    );
  }
  public deleteAuth(params: any): Observable<any> {
    return this.httpclient.delete<any>(
      this.url + '/authorization/authorization/delete?agId=' + params
    );
  }
  public locationBySupervisor(params: any, clientId: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/common/user/location/bylcncode?lcnCode=' +
        params +
        '&clientId=' +
        clientId
    );
  }

  public getClientLocation(): Observable<any> {
    // return this.httpclient.get<any>(this.url + '/location/list/location/client')

    return this.httpclient.get<any>(this.url + '/location/byClient/all');
  }
  public getReportMenu(): Observable<any> {
    // return this.httpclient.get<any>(this.url + '/location/list/location/client')

    return this.httpclient.get<any>(this.url + '/report/report/list');
  }
  public importXlOnboard(params: any, formData: any): Observable<any> {
    return this.httpclient.post<any>(
      this.url + '/onboarding/import?CreateYN=' + params,
      formData
    );
  }
  public importXlJobMela(params: any, formData: any): Observable<any> {
    return this.httpclient.post<any>(
      this.url + '/jobmelaUser/import?CreateYN=' + params,
      formData
    );
  }
  public downloadSaleDoc(file: any): Observable<any> {
    let value = file;
    value = value.split('#');
    value = value.join('@');
    return this.httpclient.get(this.url + '/util/file?fileName=' + value, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }

  public downloadCommmonFile(file: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/emp/register/get/image?sysId=' + file,
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }
  public downloadFileByFilename(file: any): Observable<any> {
    return this.httpclient.get(this.url + '/common/get/image?image=' + file, {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }
  public exportXlOnboard(type: any, header: any): Observable<any> {
    return this.httpclient.get(
      this.url +
        '/common/export/excel?type=' +
        type +
        '&headers=' +
        header +
        '&details=',
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }
  public downloadXlUser(type: any, header: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/user/download/excel?status=' + type + '&clientId=' + header,
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }
  public downloadXlJobmela(): Observable<any> {
    return this.httpclient.get(this.url + '/jobmelaUser/download/Excel', {
      responseType: 'arraybuffer',
      observe: 'response',
    });
  }
  public downloadXlApprovalhistory(type: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/common/approval/download/excel?userId=' + type,
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }
  public downloadXlemployee(type: any, header: any): Observable<any> {
    return this.httpclient.get(
      this.url +
        '/common/employee/download/excel?status=' +
        type +
        '&clientId=' +
        header,
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }
  public downloadXlZipemployee(type: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/common/download/zip?status=' + type,
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }

  public downloadXlZipemployeeSingle(type: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/common/download/zip/single?userId=' + type,
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }

  public downloadXlOnboard(type: any, header: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/onboarding/download/excel?status=' + type + header,
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }
  public submitContractApi(
    onbId: any,
    params: any,
    contractDt: any,
    aoNumber: any,
    obj: any
  ): Observable<any> {
    return this.httpclient.post<any>(
      this.url +
        '/onboarding/contract?onbId=' +
        onbId +
        '&contractNo=' +
        params +
        '&contractDt=' +
        contractDt +
        '&aoNumber=' +
        aoNumber,
      obj
    );
  }
  public updateAONumber(onbId: any, aoNumber: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/onboarding/aoNumber/Update?onbId=' +
        onbId +
        '&aoNumber=' +
        aoNumber
    );
  }
  public createAltBank(params: any): Observable<any> {
    return this.httpclient.post<any>(
      this.url + '/jobmela/account/Detail',
      params
    );
  }
  public approvalList(userId: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/common/approval/list?userId=' + userId
    );
  }
  public candidateList(status: any, params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/common/user/candidate/list?status=' + status + params
    );
  }
  public getEmployeeFilter(
    params: any,
    paramsOption: any,
    searchValue: any,
    fromNo: any,
    toNo: any
  ): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/common/user/candidate/list/filter?status=' +
        params +
        paramsOption +
        '&search=' +
        searchValue +
        '&fromNo=' +
        fromNo +
        '&toNo=' +
        toNo
    );
    // http://localhost:8087/api/onboarding/onboardList/filter?status=PA&clientId=CLI&search=8056&fromNo=1&toNo=5
  }

  public authForgot(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/auth/forgot', params);
  }
  public otpVerify(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/auth/otp/verify', params);
  }
  public forgotPassword(params: any): Observable<any> {
    return this.httpclient.post<any>(
      this.url + '/common/password/update',
      params
    );
  }
  public getReportDetails(id: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/report/param/list/by/id?reportId=' + id
    );
  }
  public getResultSet(id: any, obj: any): Observable<any> {
    let params = new URLSearchParams();

    for (let key in obj) {
      if (obj[key] == null) {
        params.set(key, '');
      } else {
        params.set(key, obj[key]);
      }
    }
    return this.httpclient.post<any>(
      this.url + '/report/param/submit?reportId=' + id + '&' + params,
      obj
    );
  }
  public downloadExcelReport(id: any, obj: any, groupBy: any): Observable<any> {
    let params = new URLSearchParams();

    for (let key in obj) {
      params.set(key, obj[key]);
    }

    return this.httpclient.get(
      this.url +
        '/report/list/excel?reportdId=' +
        id +
        '&groupBy=' +
        groupBy +
        '&' +
        params,
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }

  public downloadPDFReport(id: any, obj: any, groupBy: any): Observable<any> {
    let params = new URLSearchParams();

    for (let key in obj) {
      params.set(key, obj[key]);
    }

    return this.httpclient.get(
      this.url +
        '/report/list/pdf?reportId=' +
        id +
        '&groupBy=' +
        groupBy +
        '&' +
        params,
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }
  public getTrackDetail(params: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/common/emp/regis/track/detail',
      params
    );
  }

  // deep  EMP Bank details
  public createEmpBankDetail(params: any): Observable<any> {
    return this.httpclient.post(this.url + '/emp/bank/create', params);
  }

  public updateEmpBankDetail(params: any): Observable<any> {
    return this.httpclient.post(this.url + '/emp/bank/update', params);
  }

  public getEmpBankDetail(params: any): Observable<any> {
    return this.httpclient.get(this.url + '/emp/bank/detail?sysId=' + params);
  }

  public getEmpEmpBankDetailList(params: any): Observable<any> {
    return this.httpclient.get(this.url + '/emp/bank/list?sysId=' + params);
  }

  public getEmpEmpBankDetailDelete(params: any): Observable<any> {
    return this.httpclient.delete(
      this.url + '/emp/bank/delete?sysId=' + params
    );
  }

  // deep  EMP Idendification details

  public createEmpIdenDetail(params: any): Observable<any> {
    return this.httpclient.post(
      this.url + '/emp/idendification/create',
      params
    );
  }

  public updateEmpIdenDetail(params: any): Observable<any> {
    return this.httpclient.post(
      this.url + '/emp/idendification/update',
      params
    );
  }

  public getEmpIdenBankDetail(params: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/emp/idendification/detail?sysId=' + params
    );
  }

  public getEmpIdenDetailList(params: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/emp/idendification/list?sysId=' + params
    );
  }
  public getEmpIdenDetailDelete(params: any): Observable<any> {
    return this.httpclient.delete(
      this.url + '/emp/idendification/delete?sysId=' + params
    );
  }

  // deep  EMP Education details

  public createEmpEduDetail(params: any): Observable<any> {
    return this.httpclient.post(this.url + '/emp/education/create', params);
  }

  public updateEmpEduDetail(params: any): Observable<any> {
    return this.httpclient.post(this.url + '/emp/education/update', params);
  }

  public getEmpEduDetail(params: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/emp/education/detail?sysId=' + params
    );
  }

  public getEmpEduDetailList(params: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/emp/education/list?sysId=' + params
    );
  }

  public getEmpEduDetailDelete(params: any): Observable<any> {
    return this.httpclient.delete(
      this.url + '/emp/education/delete?sysId=' + params
    );
  }

  // deep  EMP Experience details
  public createEmpExpDetail(params: any): Observable<any> {
    return this.httpclient.post(this.url + '/emp/work/exp/create', params);
  }

  public updateEmpExpDetail(params: any): Observable<any> {
    return this.httpclient.post(this.url + '/emp/work/exp/update', params);
  }

  public getEmpExpDetail(params: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/emp/work/exp/detail?sysId=' + params
    );
  }

  public getEmpExpDetailList(params: any): Observable<any> {
    return this.httpclient.get(this.url + '/emp/work/exp/list?sysId=' + params);
  }

  public getEmpExpDetailDelete(params: any): Observable<any> {
    return this.httpclient.delete(
      this.url + '/emp/work/exp/delete?sysId=' + params
    );
  }

  // deep  EMP Family details
  public createEmpFamilyDetail(params: any): Observable<any> {
    return this.httpclient.post(this.url + '/emp/family/create', params);
  }

  public updateEmpFamilyDetail(params: any): Observable<any> {
    return this.httpclient.post(this.url + '/emp/family/update', params);
  }

  public getEmpFamilyDetail(params: any): Observable<any> {
    return this.httpclient.get(this.url + '/emp/family/detail?sysId=' + params);
  }

  public getEmpFamilyDetailList(params: any): Observable<any> {
    return this.httpclient.get(this.url + '/emp/family/list?sysId=' + params);
  }

  public getEmpFamilyDetailDelete(params: any): Observable<any> {
    return this.httpclient.delete(
      this.url + '/emp/family/delete?sysId=' + params
    );
  }

  // deep  EMP Contact details
  public createEmpContactDetail(params: any): Observable<any> {
    return this.httpclient.post(this.url + '/emp/contact/create', params);
  }

  public updateEmpContactDetail(params: any): Observable<any> {
    return this.httpclient.post(this.url + '/emp/contact/update', params);
  }

  public getEmpContactDetail(params: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/emp/contact/detail?sysId=' + params
    );
  }

  // public getEmpContactDetailList(): Observable<any> {
  //   return this.httpclient.get(this.url + '/emp/contact/list');
  // }

  public getEmpContactDetailDelete(params: any): Observable<any> {
    return this.httpclient.delete(
      this.url + '/emp/contact/delete?sysId=' + params
    );
  }

  // deep  EMP EmergencyContact details
  public updateEmpEmergencyContactDetail(params: any): Observable<any> {
    return this.httpclient.post(this.url + '/emp/contact/update', params);
  }

  // deep  EMP Skill Set details

  public createEmpSkillSetDetail(params: any): Observable<any> {
    return this.httpclient.post(this.url + '/emp/skill/set/create', params);
  }

  public updateEmpSkillSetDetail(params: any): Observable<any> {
    return this.httpclient.post(this.url + '/emp/skill/set/update', params);
  }

  public getEmpSkillSetDetail(params: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/emp/skill/set/detail?sysId=' + params
    );
  }

  public getEmpSkillSetDetailList(params: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/emp/skill/set/list?sysId=' + params
    );
  }

  public getEmpSkillSetDetailDelete(params: any): Observable<any> {
    return this.httpclient.delete(
      this.url + '/emp/skill/set/delete?sysId=' + params
    );
  }

  // public GetBankDetailsIFSC(params: any): Observable<any> {
  //   const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
  //   const url = `https://ifsc.razorpay.com/${params}`;
  //   return this.httpclient.get<any>(proxyUrl + url);
  // }
  public GetBankDetailsIFSC(params: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/common/get/emp/ifsc/detail?ifscCode=' + params
    );
  }

  public getEmpContactDetailList(params: any): Observable<any> {
    return this.httpclient.get(this.url + '/emp/contact/list?sysId=' + params);
  }

  public jobListApi(): Observable<any> {
    return this.httpclient.get(this.url + '/jobMaster/list');
  }

  public getJobPostDetail(params: any): Observable<any> {
    return this.httpclient.get(this.url + '/jobMaster/detail?agId=' + params);
  }
  public deleteJob(params: any): Observable<any> {
    return this.httpclient.delete<any>(
      this.url + '/jobMaster/delete?agId=' + params
    );
  }
  public jobpostlist(): Observable<any> {
    return this.httpclient.get<any>(this.url + '/jobMaster/list');
  }
  public jobstatuslist(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/jobMaster/list/status?recruiterYn=' + params
    );
  }

  public downloadResumeByFilename(params: any): Observable<any> {
    return this.httpclient.get(
      this.url +
        '/emp/register/get/image?sysId=' +
        params +
        '&type=' +
        'RESUME',
      {
        responseType: 'arraybuffer',
        observe: 'response',
      }
    );
  }

  public jobFilterListApi(): Observable<any> {
    return this.httpclient.get(this.url + '/jobMaster/landing/list');
  }
  public jobFilterDataListApi(
    search: any,
    title: any,
    location: any,
    category: any,
    experience: any,
    userYN: any
  ): Observable<any> {
    return this.httpclient.get(
      this.url +
        '/jobMaster/landing/list?searchValue=' +
        search +
        '&title=' +
        title +
        '&location=' +
        location +
        '&category=' +
        category +
        '&experience=' +
        experience +
        '&userYN=' +
        userYN
    );
  }

  public catgCountListApi(): Observable<any> {
    return this.httpclient.get(this.url + '/jobMaster/catg/count/list');
  }

  public viewPageApi(params: any): Observable<any> {
    return this.httpclient.get(this.url + '/jobMaster/detail?agId=' + params);
  }

  createJob(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/jobMaster/create', params);
  }
  updateJob(params: any): Observable<any> {
    return this.httpclient.post<any>(this.url + '/jobMaster/update', params);
  }

  public createApplyJobApi(params: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/jobApplUser/Create?jobId=' + params
    );
  }
  public genericSubDetails(catId: any, subCat: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/common/genric/list/bycatid?catId=' +
        catId +
        '&subCatId=' +
        subCat
    );
  }

  public getAppliedJobList(params: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/jobMaster/recruiter/landing/list?recruiterYN=' + params
    );
  }

  public getApplyJobStatus(jobId: any, userId: any): Observable<any> {
    return this.httpclient.get(
      this.url + '/tracking/status/list?jobId=' + jobId + '&userId=' + userId
    );
  }
  public getAppliedFilterJobList(
    params: any,
    searchValue: any,
    trackingStatus: any
  ): Observable<any> {
    return this.httpclient.get(
      this.url +
        '/jobMaster/recruiter/landing/list?recruiterYN=' +
        params +
        '&searchValue=' +
        searchValue +
        '&trackingStatus=' +
        trackingStatus
    );
  }

  public recruiterDetails(params: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/user/recruiter/list?recruiterYN=' + params
    );
  }
  public JobbuttonList(orderId: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/tracking/status/order/list?orderId=' + orderId
    );
  }
  public createTrackingstatus(params: any): Observable<any> {
    return this.httpclient.post<any>(
      this.url + '/tracking/status/create',
      params
    );
  }
  public getofferletterlist(userId: any, jobId: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/document/job/offer/list?userId=' + userId + '&jobId=' + jobId
    );
  }

  public sendOffer(file: any): Observable<any> {
    return this.httpclient.post(this.url + '/document/upload', file);
  }

  public CheckcountAdmindash(order: any, type: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/tracking/status/detail?order=' + order + '&type=' + type
    );
  }

  public gettotatjobaplied(): Observable<any> {
    return this.httpclient.get<any>(this.url + '/jobApplUser/detail');
  }

  // POST /api/jobMaster/update/status
  // ,StatuId:any,statusDesc:any
  public UpdateJobStatus(params: any): Observable<any> {
    return this.httpclient.post(this.url + '/jobMaster/update/status', params);
  }

  // public repostJob(agId: any, fromDt: any, toDt: any): Observable<any> {
  //   const body = { agId, fromDt, toDt }; // Creating the body object

  //   return this.httpclient.post<any>(
  //     this.url + '/jobMaster/create/job/repost',
  //     body
  //   );
  // }

  public repostJob(agId: any, fromDt: any, toDt: any): Observable<any> {
    return this.httpclient.post(
      this.url +
        '/jobMaster/create/job/repost?agId=' +
        agId +
        '&fromDt=' +
        fromDt +
        '&toDt=' +
        toDt,
      { responseType: 'arraybuffer', observe: 'response' }
    );
  }

  searchValue: any;
  public getCandidateList(searchValue: any, skill: any): Observable<any> {
    // this.searchValue = searchValue ? searchValue : null;
    return this.httpclient.get<any>(
      this.url +
        '/emp/register/get/list?searchValue=' +
        searchValue +
        '&skill=' +
        skill
    );
  }
  public getCandidateFullList(): Observable<any> {
    return this.httpclient.get<any>(this.url + '/emp/register/get/list');
  }

  public getJobstatusList(recruiterYN: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/jobMaster/status/count/list?recruiterYN=' + recruiterYN
    );
  }
  public getJobreasonList(recruiter: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/jobMaster/reason/count/list?recruiterYN=' + recruiter
    );
  }
  public getJobYearCount(
    status: any,
    selectDayTime: any,
    recruiterYn: any
  ): Observable<any> {
    return this.httpclient.get<any>(
      this.url +
        '/jobMaster/appl/count/list?status=' +
        status +
        '&selectDayTime=' +
        selectDayTime +
        '&recruiterYn=' +
        recruiterYn
    );
  }
  public getRegisterUserCount(sysId: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/emp/register/candidateDtl/count/list?sysId=' + sysId
    );
  }
  public getjobstatuschartCount(recruiterYN: any): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/jobMaster/status/count/list1?recruiterYN=' + recruiterYN
    );
  }
  public getjobCategoryCount(): Observable<any> {
    return this.httpclient.get<any>(
      this.url + '/jobMaster/category/count/list'
    );
  }
}
