<div *ngIf="getRouter()">
  <nav class="navbar navbar-light bg-light py-1 px-2 header" id="nav">
    <img
      (click)="menuvalid()"
      src="assets/image/logo.png"
      alt="logskim"
      id="logskim"
      style="cursor: pointer"
    />

    <div class="toolbar">
      <img
        src="assets/image/Path 56.png"
        alt="bell"
        (click)="log()"
        style="width: 15px; height: 17px; margin: 0 20px; cursor: pointer"
      />
      <span class="round ion-text-center">{{ notifyList?.length }}</span>

      <img
        src="{{ getImage() }}"
        #image
        onerror="this.src='assets/image/black.jpg';"
        alt="profile"
        (click)="userProfile = true"
        style="
          width: 23px;
          height: 21px;
          border-radius: 50%;
          object-fit: cover;
          background-size: 100% 100%;
          margin-right: 20px;
        "
      />
    </div>
    <div *ngIf="menuMNotify" class="commonAlleartbackground">
      <div style="top: 5%; position: relative; z-index: 100; right: -35%">
        <div class="alertCard pb-3 pt-1" style="width: 25% !important">
          <div class="commonClose py-2">
            <img
              (click)="menuMNotify = false"
              src="../../assets/image/Close.png"
              alt=""
              style="width: 16px; height: 17px"
            />
          </div>
          <div class="p-0 m-0" style="height: 250px; overflow-y: auto">
            <div
              class="commonHeaderlabel px-0"
              *ngFor="let notify of notifyList"
            >
              <div class="col-md-12 p-0 m-0" (click)="goToApprove(notify)">
                <div class="commonNotifiList">
                  <div class="col-md-12 p-0">
                    <div class="col-md-12 row p-0 m-0">
                      <div class="p-0 col-md-2">
                        <img
                          src="../assets//image/black.jpg"
                          class="notifyIcon mt-2"
                          alt=""
                        />
                      </div>
                      <div class="p-0 col-md-10">
                        <div class="notifyLabel pt-2 px-2">
                          {{ notify?.msg }}
                        </div>
                      </div>
                      <div class="p-0 col-md-2"></div>
                      <div class="p-0 col-md-10">
                        <div
                          class="commonTimeNotify py-1 pl-2"
                          timeago
                          [date]="notify?.crDt"
                        >
                          Few seconds ago
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </nav>

  <!-- (mouseenter)="hoverSizeIn()"
  (mouseleave)="hoverSizeOut()" -->
  <nav
    [ngClass]="[hideMenuSIze == false ? 'widthDinoLeftOut' : 'widthDinoLeftIn']"
    class="navbar navbar-expand d-flex flex-column align-item-start px-0"
    id="sidebar"
  >
    <ul
      class="navbar-nav-d-flex list-unstyled flex-column w-100 my-4"
      style="cursor: pointer"
    >
      <img
        [ngClass]="[
          hideMenuSIze == false ? 'menu-open-btn' : 'menu-open-btnmod'
        ]"
        src="../../assets/image/expand-btn.png"
        alt=""
        class="menu-open-btn"
        (click)="openMenu()"
      />
      <!-- <img
        [ngClass]="[
          hideMenuSIze == false ? 'menu-open-btn' : 'menu-open-btnmod'
        ]"
        src="../../assets/image/expand-btn.png"
        alt=""
        class="menu-open-btn"
        (click)="openMenu()"
      /> -->
      <!-- (mouseenter)="hoverSizeInDiv(i)"
        (mouseleave)="hoverSizeOutDiv()" -->
      <li
        (click)="hoverSizeInDivClick(i)"
        class="nav-item   p-1 pl-3  m-0 pb-1  {{ Value.menuStyle }}"
        *ngFor="let Value of MenuArray; let i = index"
      >
        <div class="p-0 m-0 py-2" (click)="checkReport(Value.menuName)">
          <img
            tooltip="{{ Value.menuName }}"
            src="assets/image/{{ Value.menuImg }}"
            alt="icon"
            class="backImgColor"
          /><span class="sidenav backSpan" *ngIf="hideMenuSIze"
            >{{ Value.menuName }}
          </span>
        </div>
        <span
          *ngIf="
            Value?.subMenu.length != 0 && Value?.showSubMenu && hideMenuSIze
          "
        >
          <div
            *ngFor="let valueF of Value?.subMenu; let j = index"
            class="backSpan pl-3 pb-2 pt-1 sideReportnav  adjestMenu {{
              valueF.menuFStyle
            }}"
            (click)="naviogateReport(Value.menuName, valueF?.reportId, j, i)"
          >
            {{ valueF.menuName }}
          </div>
        </span>
      </li>
    </ul>
  </nav>
</div>
<!-- (mouseenter)="hoverSizeInDivSubmenu(i, j)"
(mouseleave)="hoverSizeOutDivSubMenu()" -->
<!-- {{minWidth}} {{minWidthInOut}}"   [ngClass]="[hideMenuSIze==false?'widthMainOut':'widthMainIn']"-->
<div class="p-0 m-0 {{ minWidth }} {{ minWidthInOut }}">
  <div *ngIf="second" class="commonAlleartbackground">
    <div style="top: 30%; position: relative; z-index: 100">
      <div class="alertCard py-3" style="width: 25% !important">
        <div class="commonClose">
          <img
            (click)="second = false"
            src="../../assets/image/Close.png"
            alt=""
            style="width: 16px; height: 17px"
          />
        </div>
        <!-- <div class="commonHeaderAlert px-4">
                Registration Approval
            </div> -->
        <div class="commonHeaderlabel px-4">
          {{ labelFields.Are_you_sure_want_to_sign_out }}
        </div>
        <div class="col-md-12 py-4">
          <button class="back mr-3" (click)="second = false">
            {{ labelFields.No }}
          </button>

          <button class="next" (click)="signOut()">
            {{ labelFields.Yes }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="profilepicture" class="commonAlleartbackground">
    <div style="top: 30%; position: relative; z-index: 100">
      <div class="alertCard py-3">
        <div class="commonHeaderAlert">
          <p class="titlee">{{ labelFields.Profile_Picture }}</p>

          <span
            style="
              color: red;
              font: normal normal normal 16px Calibri;
              position: relative;
              top: -13px;
            "
          >
            ( File size must not exceed 150 KB )</span
          >

          <img
            src="../../assets/image/Close.png"
            alt=""
            (click)="profilepicture = false"
            class="closeicon"
            style="width: 16px; height: 17px"
          />
        </div>
        <div>
          <img
            src="{{ filenames }}"
            #image
            onerror="this.src='assets/image/black.jpg';"
            alt="dp"
            class="imagecover"
          />
        </div>
        <div class="col-md-12 py-4" *ngIf="!imageSelect">
          <button class="bluebutton" (click)="openActionSheet()">
            {{ labelFields.Upload_from_Gallery }}
          </button>
        </div>
        <div class="col-md-12 py-4" *ngIf="imageSelect">
          <button class="back mr-3" (click)="resetFile()">
            {{ labelFields.Cancel }}
          </button>

          <button class="next" (click)="submitImage()">
            {{ labelFields.Save }}
          </button>
        </div>
      </div>
    </div>
  </div>
  <div
    *ngIf="userProfile"
    class="commonAlleartbackground"
    style="z-index: 10000"
  >
    <div style="top: 30%; position: relative; z-index: 100">
      <div class="myProfileLogo py-3">
        <div class="commonHeaderAlert">
          <img
            src="../../assets/image/Close.png"
            alt=""
            (click)="userProfile = false; resetFile(); this.imageSelect = false"
            class="closeicon myProfileCLose"
            style="width: 16px; height: 17px"
          />
        </div>
        <div>
          <img
            src="{{ filenames }}"
            #image
            onerror="this.src='assets/image/black.jpg';"
            alt="dp"
            class="imageProfile"
          />
          <img
            src="../../assets/image/circle.png"
            alt=""
            (click)="
              profilepicture = true;
              userProfile = false;
              this.imageSelect = false
            "
            class="closeicon"
            style="
              width: 32px;
              height: 32px;
              right: 46px;
              top: 100px !important;
              position: absolute;
            "
          />
        </div>
        <div
          class="card-title mt-3"
          (click)="forgetPass1 = true; userProfile = false; showOTP = false"
        >
          {{ labelFields.changePassword }}
        </div>

        <div
          class="card-title mt-3"
          (click)="second = true; userProfile = false"
        >
          {{ labelFields.Sign_Out }}
        </div>
      </div>
    </div>
  </div>

  <input
    class="d-none pt-1"
    id="callFile"
    (change)="uploadFile($event)"
    type="file"
  />
  <router-outlet></router-outlet>
</div>

<div *ngIf="forgetPass1" class="commonAlleartbackground">
  <div style="top: 30%; position: relative; z-index: 100">
    <div class="alertCard py-3" style="width: 24% !important">
      <div class="commonHeaderAlert">
        <p class="titlee">{{ labelFields.otpscreen }}</p>
        <img
          src="../../assets/image/Close.png"
          alt=""
          (click)="forgetPass1 = false"
          style="width: 16px; height: 17px"
          class="closeicon"
        />
      </div>

      <div class="commonHeaderlabel px-4">
        <form>
          <div *ngIf="showOTP" class="row py-1 px-1">
            <div class="col px-1" style="text-align: left">
              <span
                for="OTP"
                style="color: red; display: block; text-align: center"
                >We sent a OTP to your email Id ({{ mailId }})</span
              >

              <label for="id_mobileNo" class="form-label m-0">{{
                labelFields.enterOTP
              }}</label>

              <input
                [(ngModel)]="otp"
                [ngModelOptions]="{ standalone: true }"
                type="text"
                class="form-control inputt"
                id="id_mobileNo"
                (keypress)="numberOnly($event)"
                required
              />
            </div>
          </div>
        </form>
      </div>

      <div class="col-md-12 py-3">
        <button class="back mr-3" (click)="forgetPass1 = false">
          {{ labelFields.Cancel }}
        </button>

        <button
          *ngIf="showOTP"
          [disabled]="otp == ''"
          (click)="passwordPage()"
          class="next"
        >
          {{ labelFields.submit }}
        </button>
        <!-- <button (click)="createRegister()" class="next"
          *ngIf="(registerForm.controls.userId.value!=null && registerForm.controls.userId.value!='' ) && filterDisable ==false  && viewwDetail ==false">{{labelFields.Update}}</button> -->
      </div>
    </div>
  </div>
</div>
<div *ngIf="forgetPass2" class="commonAlleartbackground">
  <div style="top: 30%; position: relative; z-index: 100">
    <div class="alertCard py-3" style="width: 24% !important">
      <!-- <div class="display:flex; flex-direction : row ; ">
            <p class="text-align: center">New Location</p>
      
          
          <img  src="../../assets/image/Close.png" alt="" style="  width: 20px;" class="text-align: right">
         <i class="icofont-close-circled" ></i> 

  
  </div> -->
      <div class="commonHeaderAlert">
        <p class="titlee">{{ labelFields.forgetPassword }}</p>
        <img
          src="../../assets/image/Close.png"
          alt=""
          (click)="forgetPass2 = false"
          style="width: 16px; height: 17px"
          class="closeicon"
        />
      </div>

      <div class="commonHeaderlabel px-4">
        <form>
          <div class="row py-1 px-1">
            <div class="col px-1" style="text-align: left">
              <label for="id_mobileNo" class="form-label m-0">{{
                labelFields.newpassword
              }}</label>

              <input
                [(ngModel)]="passWord"
                [type]="showPass == false ? 'password' : 'text'"
                [ngModelOptions]="{ standalone: true }"
                class="form-control inputt"
                id="id_mobileNo"
                required
              />

              <span class="passwordClass" (click)="showPass = !showPass">
                <img src="assets/image/Group 5.png" alt="icon" class="icon" />

                <div *ngIf="showPass == false" class="line">/</div>

                <!-- <i *ngIf="f.passWord.valid" class="icofont-eye-alt"></i> -->
              </span>
            </div>
          </div>
          <div class="row py-1 px-1">
            <div class="col px-1" style="text-align: left">
              <label for="id_mobileNo" class="form-label m-0">{{
                labelFields.confirmpassword
              }}</label>

              <input
                (keyup)="getfilter()"
                [type]="showPass1 == false ? 'password' : 'text'"
                [(ngModel)]="confirmPassword"
                [ngModelOptions]="{ standalone: true }"
                class="form-control inputt"
                id="id_mobileNo"
                required
              />

              <span class="passwordClass" (click)="showPass1 = !showPass1">
                <img src="assets/image/Group 5.png" alt="icon" class="icon" />

                <div *ngIf="showPass1 == false" class="line">/</div>

                <!-- <i *ngIf="f.passWord.valid" class="icofont-eye-alt"></i> -->
              </span>
              <!-- <small class="text-danger p-0 text-left col-md-7 offset-md-4" style="padding: 4px!important;"
              *ngIf="dnMatch">
         
            </small> -->
              <div
                *ngIf="dnMatch"
                class="d-block invalid-feedback text-left mt-0"
              >
                {{ labelFields.passwordError }}
              </div>
            </div>
          </div>
        </form>
      </div>
      <!-- <div class="col-md-12 py-3">
            <button  class="back mr-3" (click)="createReg=false">Cancel</button>

            <button  class="next" >Register</button>
      </div> -->

      <div class="col-md-12 py-3">
        <button class="back mr-3" (click)="forgetPass2 = false">
          {{ labelFields.Cancel }}
        </button>

        <button
          [disabled]="confirmPassword == '' || passWord == ''"
          (click)="updatePassword()"
          class="next"
        >
          {{ labelFields.submit }}
        </button>
        <!-- <button (click)="createRegister()" class="next"
          *ngIf="(registerForm.controls.userId.value!=null && registerForm.controls.userId.value!='' ) && filterDisable ==false  && viewwDetail ==false">{{labelFields.Update}}</button> -->
      </div>
    </div>
  </div>
</div>
