import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, } from '@angular/common/http';
import { Observable } from 'rxjs';
export class AddHeaderInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> { 

    // let apikey = 'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJFTVAwMDAxIiwiZXhwIjoxNjIzNDMzNTI2LCJpYXQiOjE2MjM0MTU1MjZ9.N800hMq80LkwjzAFYY7GhosHhl58lyktK6eJFl1ZDtucSmk2Xct-iNluF02iSU_lipti8Tj_KrgU0qzHpTqeGQ';
    let apikey = localStorage.getItem('logskim_keySecret');
    if (apikey == undefined) {
      apikey = ''; 
    }
    if(req.url.match('/excel')){
      const clonedRequest = req.clone({  setHeaders: { 'Content-Type':'application/ms-excel','Authorization':'Bearer '+apikey} });
      return next.handle(clonedRequest);

    }else{
     const clonedRequest = req.clone({ headers: req.headers.set('Authorization', 'Bearer '+apikey) });
     return next.handle(clonedRequest);

    } 

    // const clonedRequest = req.clone({ headers: req.headers.set('X-Auth-Token', apikey) });
   // return next.handle(clonedRequest);
  }
}
