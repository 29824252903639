import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  // {
  //   path: 'dashboard',
  //   loadChildren: () =>
  //     import('./dashboard/dashboard.module').then((m) => m.DashboardModule),
  // },
  {
    path: 'login',
    loadChildren: () =>
      import('./admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./admin-dashboard/admin-dashboard.module').then(
        (m) => m.AdminDashboardModule
      ),
  },
  {
    path: 'registration',
    loadChildren: () =>
      import('./registration/registration.module').then(
        (m) => m.RegistrationModule
      ),
  },
  {
    path: 'onboarding',
    loadChildren: () =>
      import('./onboarding/onboarding.module').then((m) => m.OnboardingModule),
  },
  {
    path: 'onboard-detail',
    loadChildren: () =>
      import('./onboard-detail/onboard-detail.module').then(
        (m) => m.OnboardDetailModule
      ),
  },
  {
    path: 'candidate-profile-list',
    loadChildren: () =>
      import('./candidate-profile-list/candidate-profile-list.module').then(
        (m) => m.CandidateProfileListModule
      ),
  },
  {
    path: 'candidate-profile-detail',
    loadChildren: () =>
      import('./candidate-profile-detail/candidate-profile-detail.module').then(
        (m) => m.CandidateProfileDetailModule
      ),
  },

  {
    path: 'candidate-profile-detail/:params/:id',
    loadChildren: () =>
      import('./candidate-profile-detail/candidate-profile-detail.module').then(
        (m) => m.CandidateProfileDetailModule
      ),
  },

  {
    path: 'onboarding-list',
    loadChildren: () =>
      import('./onboarding-list/onboarding-list.module').then(
        (m) => m.OnboardingListModule
      ),
  },
  {
    path: 'my-profile-detail',
    loadChildren: () =>
      import('./my-profile-detail/my-profile-detail.module').then(
        (m) => m.MyProfileDetailModule
      ),
  },
  {
    path: 'branch-master-list',
    loadChildren: () =>
      import('./branch-master-list/branch-master-list.module').then(
        (m) => m.BranchMasterListModule
      ),
  },
  {
    path: 'my-profile-landing-detail',
    loadChildren: () =>
      import(
        './my-profile-landing-detail/my-profile-landing-detail.module'
      ).then((m) => m.MyProfileLandingDetailModule),
  },
  {
    path: 'branch-master-detail',
    loadChildren: () =>
      import('./branch-master-detail/branch-master-detail.module').then(
        (m) => m.BranchMasterDetailModule
      ),
  },

  {
    path: 'branch-master-detail/:params/:id',
    loadChildren: () =>
      import('./branch-master-detail/branch-master-detail.module').then(
        (m) => m.BranchMasterDetailModule
      ),
  },

  {
    path: 'company-master-list',
    loadChildren: () =>
      import('./company-master-list/company-master-list.module').then(
        (m) => m.CompanyMasterListModule
      ),
  },

  {
    path: 'company-master-detail',
    loadChildren: () =>
      import('./company-master-detail/company-master-detail.module').then(
        (m) => m.CompanyMasterDetailModule
      ),
  },

  {
    path: 'company-master-detail/:params/:id',
    loadChildren: () =>
      import('./company-master-detail/company-master-detail.module').then(
        (m) => m.CompanyMasterDetailModule
      ),
  },

  {
    path: 'approval-setup-list',
    loadChildren: () =>
      import('./approval-setup-list/approval-setup-list.module').then(
        (m) => m.ApprovalSetupListModule
      ),
  },
  {
    path: 'approval-setup-detail/:action/:id',
    loadChildren: () =>
      import('./approval-setup-detail/approval-setup-detail.module').then(
        (m) => m.ApprovalSetupDetailModule
      ),
  },
  {
    path: 'supervisor-details-list',
    loadChildren: () =>
      import('./supervisor-details-list/supervisor-details-list.module').then(
        (m) => m.SupervisorDetailsListModule
      ),
  },
  {
    path: 'client-details-list',
    loadChildren: () =>
      import('./client-details-list/client-details-list.module').then(
        (m) => m.ClientDetailsListModule
      ),
  },
  {
    path: 'location-details-list',
    loadChildren: () =>
      import('./location-details-list/location-details-list.module').then(
        (m) => m.LocationDetailsListModule
      ),
  },
  {
    path: 'onboarded-employees-list',
    loadChildren: () =>
      import('./onboarded-employees-list/onboarded-employees-list.module').then(
        (m) => m.OnboardedEmployeesListModule
      ),
  },
  {
    path: 'onboarded-employees-detail',
    loadChildren: () =>
      import(
        './onboarded-employees-detail/onboarded-employees-detail.module'
      ).then((m) => m.OnboardedEmployeesDetailModule),
  },
  {
    path: 'onboarded-employees-bank-detail',
    loadChildren: () =>
      import(
        './onboarded-employees-bank-detail/onboarded-employees-bank-detail.module'
      ).then((m) => m.OnboardedEmployeesBankDetailModule),
  },
  {
    path: 'onboarded-employees-document',
    loadChildren: () =>
      import(
        './onboarded-employees-document/onboarded-employees-document.module'
      ).then((m) => m.OnboardedEmployeesDocumentModule),
  },
  {
    path: 'onboarded-employees-view',
    loadChildren: () =>
      import('./onboarded-employees-view/onboarded-employees-view.module').then(
        (m) => m.OnboardedEmployeesViewModule
      ),
  },

  {
    path: 'onboarded-employees-view/:params/:id',
    loadChildren: () =>
      import('./onboarded-employees-view/onboarded-employees-view.module').then(
        (m) => m.OnboardedEmployeesViewModule
      ),
  },

  {
    path: 'registration-detail/:params/:id',
    loadChildren: () =>
      import('./registration-detail/registration-detail.module').then(
        (m) => m.RegistrationDetailModule
      ),
  },

  {
    path: 'registration-detail',
    loadChildren: () =>
      import('./registration-detail/registration-detail.module').then(
        (m) => m.RegistrationDetailModule
      ),
  },
  {
    path: 'location-detail',
    loadChildren: () =>
      import('./location-detail/location-detail.module').then(
        (m) => m.LocationDetailModule
      ),
  },
  {
    path: 'location-detail/:params/:id',
    loadChildren: () =>
      import('./location-detail/location-detail.module').then(
        (m) => m.LocationDetailModule
      ),
  },
  {
    path: 'generic-master',
    loadChildren: () =>
      import('./generic-master-list/generic-master.module').then(
        (m) => m.GenericMasterModule
      ),
  },
  {
    path: 'generic-master-detail/:action/:id',
    loadChildren: () =>
      import('./generic-master-detail/generic-master-detail.module').then(
        (m) => m.GenericMasterDetailModule
      ),
  },
  {
    path: 'onboarding-employees-view',
    loadChildren: () =>
      import(
        './onboarding-employees-view/onboarding-employees-view.module'
      ).then((m) => m.OnboardingEmployeesViewModule),
  },
  {
    path: 'onboarding-employees-view/:params/:id',
    loadChildren: () =>
      import(
        './onboarding-employees-view/onboarding-employees-view.module'
      ).then((m) => m.OnboardingEmployeesViewModule),
  },
  {
    path: 'report/:id',
    loadChildren: () =>
      import('./report/report.module').then((m) => m.ReportModule),
  },
  {
    path: 'job-mela',
    loadChildren: () =>
      import('./job-mela/job-mela.module').then((m) => m.JobMelaModule),
  },
  {
    path: 'job-mela-list',
    loadChildren: () =>
      import('./job-mela-list/job-mela-list.module').then(
        (m) => m.JobMelaListModule
      ),
  },
  {
    path: 'onboardAlter/:id',
    loadChildren: () =>
      import('./onboard-alter/onboard-alter.module').then(
        (m) => m.OnboardAlterModule
      ),
  },
  {
    path: 'privacypolicy',
    loadChildren: () =>
      import('./privacypolicy/privacypolicy.module').then(
        (m) => m.PrivacypolicyModule
      ),
  },
  {
    path: 'c_privacypolicy',
    loadChildren: () =>
      import('./c-privacypolicy/c-privacypolicy.module').then(
        (m) => m.CPrivacypolicyModule
      ),
  },
  {
    path: 'editdocument',
    loadChildren: () =>
      import('./editdocument/editdocument.module').then(
        (m) => m.EditdocumentModule
      ),
  },

  {
    path: 'basic-register',
    loadChildren: () =>
      import('./basic-register/basic-register.module').then(
        (m) => m.BasicRegisterModule
      ),
  },
  {
    path: 'personal-detail',
    loadChildren: () =>
      import('./personal-detail/personal-detail.module').then(
        (m) => m.PersonalDetailModule
      ),
  },
  {
    path: 'personal-detail/:id',
    loadChildren: () =>
      import('./personal-detail/personal-detail.module').then(
        (m) => m.PersonalDetailModule
      ),
  },
  {
    path: 'contact-detail',
    loadChildren: () =>
      import('./contact-detail/contact-detail.module').then(
        (m) => m.ContactDetailModule
      ),
  },
  {
    path: 'contact-detail/:id',
    loadChildren: () =>
      import('./contact-detail/contact-detail.module').then(
        (m) => m.ContactDetailModule
      ),
  },
  {
    path: 'family-detail',
    loadChildren: () =>
      import('./family-detail/family-detail.module').then(
        (m) => m.FamilyDetailModule
      ),
  },
  {
    path: 'family-detail/:id',
    loadChildren: () =>
      import('./family-detail/family-detail.module').then(
        (m) => m.FamilyDetailModule
      ),
  },
  {
    path: 'educational-detail',
    loadChildren: () =>
      import('./educational-detail/educational-detail.module').then(
        (m) => m.EducationalDetailModule
      ),
  },
  {
    path: 'educational-detail/:id',
    loadChildren: () =>
      import('./educational-detail/educational-detail.module').then(
        (m) => m.EducationalDetailModule
      ),
  },
  {
    path: 'experience-detail',
    loadChildren: () =>
      import('./experience-detail/experience-detail.module').then(
        (m) => m.ExperienceDetailModule
      ),
  },
  {
    path: 'experience-detail/:id',
    loadChildren: () =>
      import('./experience-detail/experience-detail.module').then(
        (m) => m.ExperienceDetailModule
      ),
  },
  {
    path: 'identification-detail',
    loadChildren: () =>
      import('./identification-detail/identification-detail.module').then(
        (m) => m.IdentificationDetailModule
      ),
  },
  {
    path: 'identification-detail/:id',
    loadChildren: () =>
      import('./identification-detail/identification-detail.module').then(
        (m) => m.IdentificationDetailModule
      ),
  },
  {
    path: 'emergency-detail',
    loadChildren: () =>
      import('./emergency-detail/emergency-detail.module').then(
        (m) => m.EmergencyDetailModule
      ),
  },
  {
    path: 'emergency-detail/:id',
    loadChildren: () =>
      import('./emergency-detail/emergency-detail.module').then(
        (m) => m.EmergencyDetailModule
      ),
  },
  {
    path: 'skill-set',
    loadChildren: () =>
      import('./skill-set/skill-set.module').then((m) => m.SkillSetModule),
  },
  {
    path: 'skill-set/:id',
    loadChildren: () =>
      import('./skill-set/skill-set.module').then((m) => m.SkillSetModule),
  },
  {
    path: 'bank-detail',
    loadChildren: () =>
      import('./bank-detail/bank-detail.module').then(
        (m) => m.BankDetailModule
      ),
  },
  {
    path: 'bank-detail/:id',
    loadChildren: () =>
      import('./bank-detail/bank-detail.module').then(
        (m) => m.BankDetailModule
      ),
  },
  {
    path: 'candidate-registration',
    loadChildren: () =>
      import('./candidate-registration/candidate-registration.module').then(
        (m) => m.CandidateRegistrationModule
      ),
  },
  {
    path: 'preview',
    loadChildren: () =>
      import('./preview/preview.module').then((m) => m.PreviewModule),
  },
  // {
  //   path: 'apply-jobs',
  //   loadChildren: () =>
  //     import('./apply-jobs/apply-jobs.module').then((m) => m.ApplyJobsModule),
  // },
  {
    path: 'apply-jobs',
    loadChildren: () =>
      import('./apply-jobs/apply-jobs.module').then((m) => m.ApplyJobsModule),
  },
  {
    path: 'apply-jobs/:name',
    loadChildren: () =>
      import('./apply-jobs/apply-jobs.module').then((m) => m.ApplyJobsModule),
  },

  {
    path: 'admindashboard',
    loadChildren: () =>
      import('./admin-dashboard1/admin-dashboard1.module').then(
        (m) => m.AdminDashboard1Module
      ),
  },
  {
    path: 'job-post',
    loadChildren: () =>
      import('./job-post/job-post.module').then((m) => m.JobPostModule),
  },
  {
    path: 'job-post-detail',
    loadChildren: () =>
      import('./job-post-detail/job-post-detail.module').then(
        (m) => m.JobPostDetailModule
      ),
  },
  {
    path: 'function-category',
    loadChildren: () =>
      import('./function-category/function-category.module').then(
        (m) => m.FunctionCategoryModule
      ),
  },

  {
    path: 'applyjobs-viewpage',
    loadChildren: () =>
      import('./applyjobs-viewpage/applyjobs-viewpage.module').then(
        (m) => m.ApplyjobsViewpageModule
      ),
  },
  {
    path: 'applyjobs-viewpage/:id',
    loadChildren: () =>
      import('./applyjobs-viewpage/applyjobs-viewpage.module').then(
        (m) => m.ApplyjobsViewpageModule
      ),
  },
  {
    path: 'job-post-detail/:id',
    loadChildren: () =>
      import('./job-post-detail/job-post-detail.module').then(
        (m) => m.JobPostDetailModule
      ),
  },
  {
    path: 'ad-applied-jobs',
    loadChildren: () =>
      import('./ad-applied-jobs/ad-applied-jobs.module').then(
        (m) => m.AdAppliedJobsModule
      ),
  },
  {
    path: 'ad-user-profile',
    loadChildren: () =>
      import('./ad-user-profile/ad-user-profile.module').then(
        (m) => m.AdUserProfileModule
      ),
  },
  {
    path: 'ad-user-profile/:id',
    loadChildren: () =>
      import('./ad-user-profile/ad-user-profile.module').then(
        (m) => m.AdUserProfileModule
      ),
  },
  {
    path: 'recruiter-dashboard',
    loadChildren: () =>
      import('./recruiter-dashboard/recruiter-dashboard.module').then(
        (m) => m.RecruiterDashboardModule
      ),
  },
  {
    path: 'viewjob-statuspage',
    loadChildren: () =>
      import('./viewjob-statuspage/viewjob-statuspage.module').then(
        (m) => m.ViewjobStatuspageModule
      ),
  },
  {
    path: 'viewjob-statuspage/:id',
    loadChildren: () =>
      import('./viewjob-statuspage/viewjob-statuspage.module').then(
        (m) => m.ViewjobStatuspageModule
      ),
  },
  {
    path: 'user-applied-job',
    loadChildren: () =>
      import('./user-applied-job/user-applied-job.module').then(
        (m) => m.UserAppliedJobModule
      ),
  },
  {
    path: 'user-view-jobstatus',
    loadChildren: () =>
      import('./user-view-jobstatus/user-view-jobstatus.module').then(
        (m) => m.UserViewJobstatusModule
      ),
  },
  {
    path: 'user-view-jobstatus/:id',
    loadChildren: () =>
      import('./user-view-jobstatus/user-view-jobstatus.module').then(
        (m) => m.UserViewJobstatusModule
      ),
  },

  {
    path: 'job-status',
    loadChildren: () =>
      import('./job-status/job-status.module').then((m) => m.JobStatusModule),
  },
  {
    path: 'candidate-database',
    loadChildren: () =>
      import('./candidate-database/candidate-database.module').then(
        (m) => m.CandidateDatabaseModule
      ),
  },

  // {
  //   path: 'candidate-registration',
  //   loadChildren: () => import('./candidate-registration/candidate-registration.module').then(m => m.CandidateRegistrationModule),
  //   children: [
  //     { path: 'personal-detail', loadChildren: () => import('./personal-detail/personal-detail.module').then(m => m.PersonalDetailModule) },
  // { path: 'personal-detail/:sysId', loadChildren: () => import('./personal-detail/personal-detail.module').then(m => m.PersonalDetailModule) },
  // { path: 'contact-detail', loadChildren: () => import('./contact-detail/contact-detail.module').then(m => m.ContactDetailModule) },
  // { path: 'contact-detail/:sysId', loadChildren: () => import('./contact-detail/contact-detail.module').then(m => m.ContactDetailModule) },
  // { path: 'family-detail', loadChildren: () => import('./family-detail/family-detail.module').then(m => m.FamilyDetailModule) },
  // { path: 'family-detail/:sysId', loadChildren: () => import('./family-detail/family-detail.module').then(m => m.FamilyDetailModule) },
  // { path: 'educational-detail', loadChildren: () => import('./educational-detail/educational-detail.module').then(m => m.EducationalDetailModule) },
  // { path: 'educational-detail/:sysId', loadChildren: () => import('./educational-detail/educational-detail.module').then(m => m.EducationalDetailModule) },
  // { path: 'experience-detail', loadChildren: () => import('./experience-detail/experience-detail.module').then(m => m.ExperienceDetailModule) },
  // { path: 'experience-detail/:sysId', loadChildren: () => import('./experience-detail/experience-detail.module').then(m => m.ExperienceDetailModule) },
  // { path: 'identification-detail', loadChildren: () => import('./identification-detail/identification-detail.module').then(m => m.IdentificationDetailModule) },
  // { path: 'identification-detail/:sysId', loadChildren: () => import('./identification-detail/identification-detail.module').then(m => m.IdentificationDetailModule) },
  // { path: 'emergency-detail', loadChildren: () => import('./emergency-detail/emergency-detail.module').then(m => m.EmergencyDetailModule) },
  // { path: 'emergency-detail/:sysId', loadChildren: () => import('./emergency-detail/emergency-detail.module').then(m => m.EmergencyDetailModule) },
  // { path: 'skill-set', loadChildren: () => import('./skill-set/skill-set.module').then(m => m.SkillSetModule) },
  // { path: 'skill-set/:sysId', loadChildren: () => import('./skill-set/skill-set.module').then(m => m.SkillSetModule) },
  // { path: 'bank-detail', loadChildren: () => import('./bank-detail/bank-detail.module').then(m => m.BankDetailModule) },
  // { path: 'bank-detail/:sysId',loadChildren: () => import('./bank-detail/bank-detail.module').then(m => m.BankDetailModule) },
  //     // Add more child routes as needed
  //     //{ path: '', redirectTo: '', pathMatch: 'full' } // Default child route
  //   ]
  // },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
