import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { MatIconModule } from '@angular/material/icon';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { ToastrModule } from 'ng6-toastr-notifications';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { ChartsModule } from 'ng2-charts';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';

import { FormsModule } from '@angular/forms';
import { httpInterceptorProviders } from './interceptor';
import { TimeagoModule } from 'ngx-timeago';
//import { CandidateRegistrationComponent } from './candidate-registration/candidate-registration.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';

// import { NotificationRoutingModule } from './notification/notification-routing.module';
// import { TextToSpeech } from '@ionic-native/text-to-speech/ngx';
// import { TimeAgoPipe } from 'time-ago-pipe';
@NgModule({
  declarations: [
    AppComponent,
    // TimeAgoPipe
  ],

  imports: [
    NgbModule,
    BsDropdownModule.forRoot(),
    ChartsModule,
    TimeagoModule.forRoot(),
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ModalModule.forRoot(),
    ToastrModule.forRoot(),
    FormsModule,
    MatIconModule,
    TooltipModule.forRoot(),

    BrowserAnimationsModule,
    HttpClientModule,
    NgMultiSelectDropDownModule.forRoot(),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],

  providers: [httpInterceptorProviders],
  bootstrap: [AppComponent],
  // exports:[TimeAgoPipe]
})
export class AppModule {}
