import {
  Component,
  OnInit,
  HostListener,
  ElementRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  SimpleChanges,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ToastrManager } from 'ng6-toastr-notifications';
import { environment } from 'src/environments/environment';
import { AppService } from './app.service';
import location from '../assets/lang/app-html.json';
import { interval, Subscription } from 'rxjs';

declare const $: any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit {
  subscription: Subscription;
  labelFields = location;
  mobileNo = '';
  mailId: any = '';
  otp = '';
  title = 'LogSkim';
  selectedIndex: any = null;
  second: any;
  forgetPass1 = false;
  popup = false;
  uploadImg: boolean;
  menuMNotify = false;
  reportMenuMerge: any;
  selectedSubMenuIndex: number;
  log() {
    this.menuMNotify = true;
  }
  profilepicture: any;
  profile() {
    this.profilepicture = true;
  }
  forgetPass2 = false;
  showPass1 = false;
  dnMatch = false;
  getfilter() {
    // here we have the 'passwords' group
    let pass = this.passWord;
    let confirmPass = this.confirmPassword;

    if (confirmPass == '' || confirmPass == null || confirmPass == undefined) {
      this.dnMatch = false;
      return;
    }
    this.dnMatch = pass === confirmPass ? false : true;
  }

  showEmail: any = '';
  hashKey: any = '';
  callForget(key: any) {
    this.hashKey = key;
    this.forgetPass1 = true;
    this.showOTP = true;
    this.forgetPass2 = false;
    this.mailId = localStorage.getItem('regMailId');
    this.cdr.detectChanges();
  }
  loaderShow = false;
  passwordPage() {
    let json = {
      mailId: this.mobileNo,
      otp: this.otp,
      adminYn: 'Y',
      hashKey: this.hashKey,
    };
    this.loaderShow = true;
    this.appService.otpVerify(json).subscribe(
      (data4) => {
        this.loaderShow = false;

        this.router.navigate(['./login']);
        this.forgetPass1 = false;
        this.toastr.successToastr('OTP verified Successfully!');
      },
      (error: any) => {
        this.loaderShow = false;
        this.toastr.errorToastr('Invalid OTP.Please try again!');

        // })
      }
    );
  }
  showPass = false;

  updatePassword() {
    let json = {
      mobileNo: this.mobileNo,
      passWord: this.passWord,
      confirmPassword: this.confirmPassword,
      // "hashKey":this.forgotList.hashKey
    };
    this.loaderShow = true;
    this.appService.forgotPassword(json).subscribe(
      (data4) => {
        this.loaderShow = false;
        this.forgetPass2 = false;
        this.forgetPass1 = false;

        this.otp = '';
        this.toastr.successToastr('Password updated Successfully!');
      },
      (error: any) => {
        this.loaderShow = false;
        this.toastr.errorToastr(error.error);

        // })
      }
    );
  }
  confirmPassword = '';
  passWord = '';
  showMob = true;

  showOTP = false;

  forgotList: any;
  sendOTP() {
    let json = {
      mailId: this.mobileNo,
      adminYn: 'Y',
    };
    this.loaderShow = true;
    this.appService.authForgot(json).subscribe(
      (data4) => {
        this.toastr.successToastr('OTP sent successfully!');
        this.loaderShow = false;
        this.forgotList = data4;
        this.showOTP = true;
        this.showMob = false;
      },
      (error: any) => {
        this.toastr.errorToastr('Technical Error!');
        this.loaderShow = false;
        // })
      }
    );
  }
  numberOnly(event: any): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }
  constructor(
    public toastr: ToastrManager,
    public router: Router,
    public route: ActivatedRoute,
    public appService: AppService,
    private cdr: ChangeDetectorRef
  ) {}
  userProfile = false;
  MenuArray: any = [
    {
      menuName: 'Dashboard',
      menuStyle: '',
      menuImg: 'Group 577.png',
      menuUrl: 'dashboard',
      showSubMenu: false,
      subMenu: [],
    },

    // { menuName: 'Registration Master', menuStyle: '', menuImg: 'Group 578.png', menuUrl: 'registration', showSubMenu: false, subMenu: [] },
    // { menuName: 'Onboarding Master', menuStyle: '', menuImg: 'Path 358@2x.png', menuUrl: 'onboarding-list', showSubMenu: false, subMenu: [] },
    //{ menuName: 'Employee Details', menuStyle: '', menuImg: 'Group 583.png', menuUrl: 'candidate-profile-list', showSubMenu: false, subMenu: [] },
    // { menuName: 'Location Master', menuStyle: '', menuImg: 'Group 584.png', menuUrl: 'location-details-list' },
    // { menuName: 'Client Master', menuStyle: '', menuImg: 'Group 585.png', menuUrl: 'client-details-list' },
    // { menuName: 'Supervisor/Ops Executive Master', menuStyle: '', menuImg: 'Group 581.png', menuUrl: 'supervisor-details-list' },
    // { menuName: 'Contract Generation', menuStyle: '', menuImg: 'Group 579.png', menuUrl: '' },
    // { menuName: 'Onboarded Employees', menuStyle: '', menuImg: 'Group 580.png', menuUrl: 'onboarded-employees-list' },
    // {
    //   menuName: 'Candiadate Registration', menuStyle: '', menuImg: 'Group 582.png', menuUrl: null, showSubMenu: false, subMenu: [
    //    { menuName: 'Location Master', menuStyle: '', subMenuUrl: 'location-details-list' },
    //    { menuName: 'Client Master', menuStyle: '', subMenuUrl: 'client-details-list' },
    //      { menuName: 'Supervisor/Ops Executive Master', menuStyle: '', subMenuUrl: 'supervisor-details-list' },
    //      { menuName: 'Approval Master', menuStyle: '', subMenuUrl: 'approval-setup-list' },
    //     { menuName: 'Company Master', menuStyle: '', subMenuUrl: 'company-master-list' },
    //      { menuName: 'Branch Master', menuStyle: '', subMenuUrl: 'branch-master-list' },
    //     { menuName: 'Generic Master', menuStyle: '', subMenuUrl: 'generic-master' },
    //     { menuName: 'Job Mela Master', menuStyle: '', subMenuUrl: 'job-mela' }
    //   ]
    // },
    // { menuName: 'Approval Master', menuStyle: '', menuImg: 'Group 586.png', menuUrl: 'approval-setup-list' },
    // { menuName: 'Company Master', menuStyle: '', menuImg: 'Group 587.png', menuUrl: 'company-master-list' },
    // { menuName: 'Branch Master', menuStyle: '', menuImg: 'Group 588.png', menuUrl: 'branch-master-list' },
    // { menuName: 'Generic Master', menuStyle: '', menuImg: 'Group 588.png', menuUrl: 'generic-master' },
    // { menuName: 'Job Mela Master', menuStyle: '', menuImg: 'Group 588.png', menuUrl: 'job-mela' },

    {
      menuName: 'Candidate Registration',
      menuStyle: '',
      menuImg: 'Group 582.png',
      menuUrl: 'candidate-registration',
      showSubMenu: false,
      subMenu: [
        // {
        //   menuName: 'Preview',
        //   menuStyle: '',
        //   subMenuUrl: 'preview',
        // },
        {
          menuName: 'Personal Details',
          menuStyle: '',
          subMenuUrl: 'personal-detail',
        },
        {
          menuName: 'Contact Details',
          menuStyle: '',
          subMenuUrl: 'contact-detail',
        },
        {
          menuName: 'Family Details',
          menuStyle: '',
          subMenuUrl: 'family-detail',
        },
        {
          menuName: 'Educational Details',
          menuStyle: '',
          subMenuUrl: 'educational-detail',
        },
        {
          menuName: 'Experience Details',
          menuStyle: '',
          subMenuUrl: 'experience-detail',
        },
        {
          menuName: 'Identification Details',
          menuStyle: '',
          subMenuUrl: 'identification-detail',
        },
        { menuName: 'Skill Set', menuStyle: '', subMenuUrl: 'skill-set' },
        //{ menuName: 'Emergency Contact Details', menuStyle: '', subMenuUrl: 'emergency-detail' },
        { menuName: 'Bank Details', menuStyle: '', subMenuUrl: 'bank-detail' },
      ],
    },

    // { menuName: 'Report', menuStyle: '', menuImg: 'Group 577.png', menuUrl: 'null' },
    //  { menuName: 'Sales', icon: 'icofont-list', childMenu: [{ menuactive: '', menuHover: false, funUrl: 'sales/invoice-list', menuName: 'Invoice' }] },
    {
      menuName: 'My Profile',
      menuStyle: '',
      menuImg: 'Group 582.png',
      menuUrl: 'my-profile-detail',
      showSubMenu: false,
      subMenu: [],
    },
    {
      menuName: 'Apply Jobs',
      menuStyle: '',
      menuImg: 'Group 577.png',
      // menuUrl: 'apply-jobs',
      menuUrl: 'function-category',
      showSubMenu: false,
      subMenu: [],
    },
    {
      menuName: 'Track Application',
      menuStyle: '',
      menuImg: 'Group 577.png',
      // menuUrl: 'apply-jobs',
      menuUrl: 'user-applied-job',
      showSubMenu: false,
      subMenu: [],
    },
  ];
  sysId: any;
  ngOnInit() {
    // const source = interval(5000);
    // this.subscription = source.subscribe((val) => {
    //   this.filenames = '';
    //   this.getUserImage();
    // });
    // this.hoverSizeIn();
    this.getUserImage();
    let value = localStorage.getItem('menuSideHandle');
    this.sysId = localStorage.getItem('sysId');
    if (value == '1') {
      this.hideMenuSIze = true;
      this.minWidthInOut = 'widthMainIn';
      this.showMenuVaild = true;
    }
    let adminYN = localStorage.getItem('superAdminYn');
    let recruiter = localStorage.getItem('recruiterYn');

    if (adminYN == 'Y') {
      this.MenuArray = [
        {
          menuName: 'Dashboard',
          menuStyle: '',
          menuImg: 'Group 577.png',
          menuUrl: 'admindashboard',
          showSubMenu: false,
          subMenu: [],
        },

        {
          menuName: 'Job Posting',
          menuStyle: '',
          menuImg: 'Group 577.png',
          menuUrl: 'job-post',
          showSubMenu: false,
          subMenu: [],
        },
        {
          menuName: 'Job Status',
          menuStyle: '',
          menuImg: 'Group 577.png',
          menuUrl: 'job-status',
          showSubMenu: false,
          subMenu: [],
        },
        {
          menuName: 'Candidate Database',
          menuStyle: '',
          menuImg: 'Group 582.png',
          menuUrl: 'candidate-database',
          showSubMenu: false,
          subMenu: [],
        },

        {
          menuName: 'Application List',
          menuStyle: '',
          menuImg: 'Group 577.png',
          menuUrl: 'ad-applied-jobs',
          showSubMenu: false,
          subMenu: [],
        },
      ];
    } else if (recruiter == 'Y') {
      this.MenuArray = [
        {
          menuName: 'Dashboard',
          menuStyle: '',
          menuImg: 'Group 577.png',
          menuUrl: 'recruiter-dashboard',
          showSubMenu: false,
          subMenu: [],
        },
        {
          menuName: 'Job Posting',
          menuStyle: '',
          menuImg: 'Group 577.png',
          menuUrl: 'job-post',
          showSubMenu: false,
          subMenu: [],
        },
        {
          menuName: 'Job Status',
          menuStyle: '',
          menuImg: 'Group 577.png',
          menuUrl: 'job-status',
          showSubMenu: false,
          subMenu: [],
        },
        {
          menuName: 'Candidate Database',
          menuStyle: '',
          menuImg: 'Group 582.png',
          menuUrl: 'candidate-database',
          showSubMenu: false,
          subMenu: [],
        },
        {
          menuName: 'Application List',
          menuStyle: '',
          menuImg: 'Group 577.png',
          menuUrl: 'ad-applied-jobs',
          showSubMenu: false,
          subMenu: [],
        },
      ];
    }
    this.getNotification();
  }
  hoverSizeInDiv(ind: any) {
    for (let index = 0; index < this.MenuArray.length; index++) {
      const element = this.MenuArray[index];
      if (this.selectedIndex != index) {
        element['menuStyle'] = '';
      }
    }
    this.MenuArray[ind].menuStyle = 'hoverClass';
  }
  hoverSizeInDivSubmenu(ind: any, subIndex: any) {
    for (let index = 0; index < this.MenuArray.length; index++) {
      const element = this.MenuArray[index];
      for (let indexF = 0; indexF < element.subMenu.length; indexF++) {
        const element1 = element.subMenu[indexF];
        if (this.selectedSubMenuIndex != indexF) {
          element1['menuFStyle'] = '';
        }
        // element1['menuFStyle'] = '';
      }
    }
    this.MenuArray[ind].subMenu[subIndex].menuFStyle = 'hoverReportClass';
  }
  hoverSizeInDivClick(ind: any) {
    this.selectedIndex = ind;
    for (let index = 0; index < this.MenuArray.length; index++) {
      const element = this.MenuArray[index];
      element['menuStyle'] = '';
    }

    this.MenuArray[ind].menuStyle = 'hoverClass';
    //this.router.navigate(['/' + this.MenuArray[ind].menuUrl]);

    if (this.MenuArray[ind].subMenu.length != 0) {
      // if (this.MenuArray[ind].showSubMenu != true) {
      for (let index = 0; index < this.MenuArray.length; index++) {
        const element = this.MenuArray[index];
        element['showSubMenu'] = false;
        if (element.menuName == 'Candidate Registration') {
          this.openMenudummy();
          // this.MenuArray[ind].subMenu.menuFStyle = 'hoverClass';
          // for (let i = 0; i < this.MenuArray[ind].subMenu.length; i++) {
          //   const element = this.MenuArray[ind].subMenu[i];
          //   if (element.menuFStyle == '') {
          //     element.menuFStyle = '';
          //   } else {
          //     element.menuFStyle = 'hoverClass2';
          //   }
          // }
        }
      }

      this.MenuArray[ind].showSubMenu = !this.MenuArray[ind].showSubMenu;
      // if (this.MenuArray[ind].showSubMenu != true) {
      //   this.MenuArray[ind].showSubMenu = !this.MenuArray[ind].showSubMenu;
      // }
    } else {
      this.MenuArray[ind].showSubMenu = false;
      for (let index = 0; index < this.MenuArray.length; index++) {
        const element = this.MenuArray[index];
        element.showSubMenu = false;
      }
      this.router.navigate(['/' + this.MenuArray[ind].menuUrl]);
    }
  }
  checkReport(params: any) {
    if (params == 'Reports') {
      this.showSubReport = !this.showSubReport;
    } else {
      this.showSubReport = false;
      for (let index = 0; index < this.reportMenuMerge.length; index++) {
        const element = this.reportMenuMerge[index];
        element['menuFStyle'] = '';
      }
    }
  }
  showSubReport = false;
  openActionSheet() {
    document.getElementById('callFile')!.click();
  }
  fileload: any;
  filenames: any = this.appService.getPictureUrl();
  imageName: any = '';
  files: any;
  uploadFile(event: any) {
    this.imageSelect = true;

    if (event.target.files[0].size > 150000) {
      this.toastr.errorToastr(' File size must not exceed 150 KB ');
      console.log(event.target.files[0].size);
      this.imageName = '';
      this.imageSelect = false;
      return;
    }

    // this.fieldChange = true
    // console.log(event);
    // this.legendsArrayInd.v_img = event;
    this.imageName = event.target.files[0].name;
    // this.path = this.imageName;
    // this.loginForm.controls.path.setValue(this.imageName)
    this.files = event.target.files[0];
    // this.pathShow = true;
    // this.files = event
    // .target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    var self = this;
    reader.onload = function () {
      self.filenames = reader.result;
    };
  }
  goToApprove(data: any) {
    if (data.txnRef == 'ONB') {
      this.menuMNotify = false;
      this.router.navigate(['/onboarding-list']);
    } else {
      this.menuMNotify = false;

      this.router.navigate(['/registration']);
    }
  }
  go() {}
  notifyList: any;
  statusType = 'Registration';
  getNotification() {
    this.loaderShow = true;
    this.appService.getReportMenu().subscribe(
      (dataMenuReport) => {
        this.reportMenuMerge = dataMenuReport;
        if (this.reportMenuMerge.length != 0) {
          let valueMenuArray = [];
          for (let index = 0; index < this.reportMenuMerge.length; index++) {
            const element = this.reportMenuMerge[index];
            element['menuName'] = element.reportDesc;
            element['subMenuUrl'] = 'report';
            element['menuStyle'] = '';
          }
          //this.MenuArray.push({ menuName: 'Reports', menuStyle: '', menuImg: 'Group 577.png', menuUrl: 'null', showSubMenu: false, subMenu: this.reportMenuMerge })
        }
        this.getNotify();
      },
      (error: any) => {
        this.loaderShow = false;
        // this.toastr.errorToastr("Technical Error!")

        // })
      }
    );
  }
  getNotify() {
    let value = window.localStorage.getItem('logskim_UserId');

    this.appService.getNotification(value, 'Y').subscribe((data4) => {
      this.loaderShow = false;
      this.notifyList = data4;

      for (let index = 0; index < this.notifyList.length; index++) {
        const element = this.notifyList[index];

        if (element['txnRef'] == 'ONB') {
          this.statusType = 'Onboarding';
        }
        if (element['status'] == 'PA') {
          element['msg'] =
            element['userName'] +
            ' ' +
            this.statusType +
            ' request' +
            ' waiting For approval by ' +
            element['toUserType'] +
            ' ' +
            element['toUserName'];
        } else if (element['status'] == 'R') {
          element['msg'] =
            element['userName'] +
            ' ' +
            this.statusType +
            ' request rejected by ' +
            element['fmUserType'] +
            ' ' +
            element['fmUserName'];
        } else if (element['status'] == 'QA') {
          element['msg'] =
            element['userName'] +
            ' ' +
            this.statusType +
            ' request' +
            ' waiting For approval by ' +
            element['toUserType'] +
            ' ' +
            element['toUserName'];
        }
      }

      // }, (error: any) => {
      //   this.loaderShow=false;
      //   this.toastr.errorToastr("Technical Error!")

      // })
    });
  }
  naviogateReport(menuName: any, params: any, id: any, mainIndex: any) {
    console.log(params);
    this.selectedSubMenuIndex = id;

    // this.MenuArray[mainIndex].subMenu.forEach(
    //   (submenu: { menuFStyle: string }, index: any) => {
    //     submenu.menuFStyle = index === id ? 'hoverReportClass' : '';
    //   }
    // );
    // this.hideMenuSIze = true;
    for (let index = 0; index < this.MenuArray.length; index++) {
      const element = this.MenuArray[index];
      for (let indexF = 0; indexF < element.subMenu.length; indexF++) {
        const element1 = element.subMenu[indexF];

        element1['menuFStyle'] = '';
      }
    }
    this.MenuArray[mainIndex].subMenu[id].menuFStyle = 'hoverReportClass';
    if (menuName == 'Reports') {
      setTimeout(() => {
        this.router
          .navigateByUrl('/branch-master-list', { skipLocationChange: true })
          .then(() => {
            this.router.navigate(['/report/' + params]);
          });
      }, 500);
    } else {
      //console.log('menu');
      this.router.navigate([
        '/' + this.MenuArray[mainIndex].subMenu[id].subMenuUrl,
      ]);
    }
  }
  // getUserImage() {
  //   this.imageSelect = false;

  //   this.filenames =
  //     environment.baseUrl +
  //     '/user/get/image?userId=' +
  //     localStorage.getItem('ProfileImage');

  //   console.log(this.filenames);
  // }

  getUserImage() {
    //this.imageSelect = false;
    // this.filenames =
    //   environment.baseUrl +
    //   '/emp/register/get/image?sysId=' +
    //   localStorage.getItem('sysId') +
    //   '&type=' +
    //   'PHOTO';
    // console.log(this.filenames);
    this.filenames = this.appService.getPictureUrl();
    this.cdr.detectChanges();
  }

  getImage() {
    return this.appService.getPictureUrl();
  }
  // filenames = this.appService.getPictureUrl();

  userId: any;
  imageSelect = false;
  resetFile() {
    this.getUserImage();
    this.imageSelect = false;
    // this.files=''
  }
  submitImage() {
    let formData = new FormData();

    if (this.imageName == '') {
      return;
    }

    this.userId = localStorage.getItem('logskim_UserId');
    formData.append('userId', this.userId);

    formData.append('userImage', this.files);

    this.loaderShow = true;
    this.appService.userImageUpload(formData).subscribe((data) => {
      this.loaderShow = false;
      this.uploadImg = false;

      setTimeout(() => {
        this.profilepicture = false;

        this.filenames = environment.baseUrl + '/user/get/image?userId=null';

        setTimeout(() => {
          this.getUserImage();
        }, 500);
      }, 500);
    });
  }
  signOut() {
    localStorage.clear();
    this.second = false;
    this.router.navigate(['login']);
  }
  showMenuVaild = false;
  menuvalid() {
    this.showMenuVaild = !this.showMenuVaild;
    if (this.showMenuVaild == true) {
      localStorage.setItem('menuSideHandle', '1');
    } else {
      localStorage.setItem('menuSideHandle', '2');
    }
    let recruiter = localStorage.getItem('recruiterYn');
    let superAdmin = localStorage.getItem('superAdminYnYN');
    if (recruiter == 'Y' && superAdmin == 'N') {
      this.router.navigate(['/recruiter-dashboard']);
    } else if (superAdmin == 'Y') {
      this.router.navigate(['/admindashboard']);
    } else {
      this.router.navigate(['/dashboard']);
    }
    this.hideMenuSIze = true;
    this.minWidthInOut = 'widthMainIn';
    // this.router.navigate(['admin-dashboard']);
  }
  openMenu() {
    this.hideMenuSIze = !this.hideMenuSIze;
    if (!this.hideMenuSIze) {
      this.minWidthInOut = 'widthMainOut';
    } else {
      this.minWidthInOut = 'widthMainIn';
    }
    // this.adminPortal.screenSize(this.hideMenuSIze);
  }
  openMenudummy() {
    this.hideMenuSIze = true;

    this.minWidthInOut = 'widthMainIn';

    // this.adminPortal.screenSize(this.hideMenuSIze);
  }

  fixedMenuField = 'fixedMenu';
  hideMenuSIze = false;
  hoverSizeIn() {
    console.log(1);
    let value = localStorage.getItem('menuSideHandle');
    if (value == '1') {
      return;
    }
    this.hideMenuSIze = true;
    this.minWidthInOut = 'widthMainIn';
  }
  hoverSizeOut() {
    let value = localStorage.getItem('menuSideHandle');
    if (value == '1') {
      return;
    }
    this.hideMenuSIze = false;
    this.minWidthInOut = 'widthMainOut';
  }
  hoverSizeOutDiv() {
    for (let index = 0; index < this.MenuArray.length; index++) {
      const element = this.MenuArray[index];
      if (this.selectedIndex != index) {
        element['menuStyle'] = '';
      }
    }
  }
  hoverSizeOutDivSubMenu() {
    for (let index = 0; index < this.MenuArray.length; index++) {
      const element = this.MenuArray[index];
      for (let indexF = 0; indexF < element.subMenu.length; indexF++) {
        const element1 = element.subMenu[indexF];
        if (this.selectedSubMenuIndex != indexF) {
          element1['menuFStyle'] = '';
        }
      }
    }
  }
  minWidth = 'minWidth';
  minWidthInOut = 'widthMainOut';
  getRouter() {
    this.minWidth = 'minWidth';
    if (
      this.router.url === '/login' ||
      this.router.url === '/basic-register' ||
      this.router.url === '/privacypolicy'
    ) {
      this.minWidth = '';
      this.minWidthInOut = '';
      return false;
    }
    if (this.minWidthInOut == '') {
      this.hideMenuSIze = false;
      this.minWidthInOut = 'widthMainOut';
    }
    return true;
  }
}
